import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../../app/theme/colors";

export const useGeneralDataSectionStyles = makeStyles()((theme: Theme) => ({
  title: {
    color: colors.primary,
    fontWeight: "bold",
  },
  phoneInput: {
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.1)',
    padding: theme.spacing(1),
    fontSize: '1rem',
    border: `1px solid ${theme.palette.divider}`,
    '&:focus': {
      outline: 'none',
      borderColor: theme.palette.primary.main,
    },
  },
  boxContainer: {
    background: "#F3F3F3",
    boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
    padding: "20px",
  },
  inputTitle: { color: colors.inputLabel, textAlign: "start" },
  boxWrapper: {
    backgroundColor: "white",
  },
  autocomplete: {
    backgroundColor: colors.white,
    borderRadius: "10px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
  },
  facebookIcon: {
    fontSize: "30px",
    color: colors.icons.facebook,
  },
  instagramIcon: {
    fontSize: "30px",
    color: colors.icons.instagram,
  },
  twitterIcon: {
    fontSize: "30px",
    color: colors.icons.twitter,
  },
  youtubeIcon: {
    fontSize: "30px",
    color: colors.icons.youtube,
  },
  linkedInIcon: {
    fontSize: "30px",
    color: colors.icons.linkedIn,
  },
  deleteIcon: {
    fontSize: 30,
    marginLeft: "10px",
    color: colors.red,
    cursor: "pointer",
  },
  controlPointIcon: {
    fontSize: 30,
    marginLeft: "10px",
    color: colors.icons.controlPointIcon,
    cursor: "pointer",
  },
  inputWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  sectionTitle: {
    marginLeft: "5px",
    marginRight: "5px",
  },
}));
