import { makeStyles } from "tss-react/mui";
import { Theme } from "@mui/material/styles";
import { colors } from "../../../app/theme/colors";

export const useFullDetailsModalStyles = makeStyles()((theme: Theme) => ({
  card: {
    display: "flex",
    background: colors.searchLayout,
    borderRadius: "15px",
    cursor: "pointer",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.01)",
    },
  },
  box: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  cardMedia: {
    width: 250,
  },
  title: {
    color: colors.primary,
    fontWeight: "bold",
  },
  icon: {
    color: colors.primary,
    marginRight: "5px",
  },
  label: {
    color: colors.primary,
  },
  contactContent: {
    marginLeft: "5px",
    textDecoration: "none",
    color: colors.inputLabel,
    "&:hover": {
      color: colors.primary,
    },
  },
  contactContentAddress: {
    marginLeft: "5px",
    textDecoration: "none",
    color: colors.inputLabel,
  },
  avatar:{
    width: 200,
    height: 200,
    fontSize: "50px"
  },
  autocomplete: {
    backgroundColor: colors.white,
    borderRadius: "10px",
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
  },
}));