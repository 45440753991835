import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { Dispatch, SetStateAction, useEffect } from "react";
import { CategoryShortDetail } from "../../../../models/categories/categoryShortDetail";
import { CompanyStepper } from "../../../../models/companies/saveCompany/companyStepper";
import { useGeneralDataSectionStyles } from "../generalDataSectionStyles";
import { useBasicInputStyles } from "../../../../components/basicInput/basicInputStyles";
import { IsCompanyCategoryValid } from "../../../../common/validations/generalSectionValidations";

type Props = {
  categoryShortDetails: CategoryShortDetail[] | null;
  categories: CategoryShortDetail[];
  setCompanyCategories: (categories: CategoryShortDetail[]) => void;
  validate: boolean;
};

export default function SelectCategories({
  categoryShortDetails,
  categories,
  setCompanyCategories,
  validate,
}: Props) {
  const { classes } = useGeneralDataSectionStyles();
  const { classes: basicInputStyles } = useBasicInputStyles();

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const error = validate && !IsCompanyCategoryValid(categories);

  const onChange = (
    e: React.SyntheticEvent<Element, Event>,
    newCategories: CategoryShortDetail[]
  ) => {
    const newCategory = newCategories.find((x) => !categories.includes(x));

    // Removed
    if (!newCategory) {
      const removed = categories.find((x) => !newCategories.includes(x));

      if (!removed) {
        return;
      }

      if (!removed.parentId) {
        // Remove parent and its child
        const updatedCategories = categories.filter(
          (x) => x.id !== removed.id && x.parentId !== removed.id
        );
        setCompanyCategories([...updatedCategories]);
      } else {
        setCompanyCategories([...newCategories]);
      }

      return;
    }

    // Child
    if (newCategory.parentId) {
      const parent = categories.find(
        (x) => !x.parentId && x.id === newCategory.parentId
      );

      if (parent) {
        setCompanyCategories([...categories, newCategory]);
      } else {
        const findParent = categoryShortDetails?.find(
          (x) => x.id === newCategory.parentId
        );

        if (!findParent) {
          return;
        }

        setCompanyCategories([...categories, findParent, newCategory]);
      }

      return;
    }

    setCompanyCategories([...categories, newCategory]);
  };

  return (
    <>
      <Autocomplete
        multiple
        value={categories}
        onChange={(e, newVal) => {
          onChange(e, newVal);
        }}
        options={categoryShortDetails ?? []}
        disableCloseOnSelect
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => (
          <li {...props} style={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{
                marginRight: 8,
                marginLeft: option.parentId ? 24 : 0,
              }}
              checked={selected}
            />
            {option.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Djelatnost"
            variant="outlined"
            size="small"
            fullWidth
            error={error}
          />
        )}
        className={classes.autocomplete}
        sx={{
          boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
        }}
        id="select-category-and-subcategories"
      />
      {error && (
        <span className={basicInputStyles.errorMessage}>
          Djelatnost firme je obavezna.
        </span>
      )}
    </>
  );
}
