import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { PoslovniImenikEnv } from "../../../app/redux/api";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { colors } from "../../../app/theme/colors";
import {
  AreAllTextAdvertisementsValid,
  IsBannerPhotoValid,
  IsBannerResolutionValid,
  IsBannerUrlValid,
  IsBannerValid,
  IsTextAdDescriptionValid,
  IsTextAdFromDateValid,
  IsTextAdTitleValid,
  IsTextAdToDateValid,
  IsTextAdValid,
} from "../../../common/validations/advertisementSectionValidations";
import AppButton from "../../../components/appButton/appButton";
import BasicInput from "../../../components/basicInput/basicInput";
import { useBasicInputStyles } from "../../../components/basicInput/basicInputStyles";
import AddIconButton from "../../../components/icons/AddIconButton";
import RemoveIconButton from "../../../components/icons/RemoveIconButton";
import useGetAllBannerPhotoResolutions from "../../../hooks/advertisements/useGetAllBannerPhotoResolutions";
import { CompanyStepper } from "../../../models/companies/saveCompany/companyStepper";
import {
  removeBannerPhoto,
  selectSavedBannerPhotoFilePath,
} from "../../../slice/companySlice";
import { EmptyGuid, INIT_TEXT_ADVERTISEMENT } from "../../stepper/initialData";
import { useBannerSectionStyles } from "./bannerSectionStyles";

interface Props {
  company: CompanyStepper;
  setCompany: Dispatch<SetStateAction<CompanyStepper>>;
  validate: boolean;
}

export default function BannerSection({
  company,
  setCompany,
  validate,
}: Props) {
  const dispatch = useAppDispatch();
  const { classes } = useBannerSectionStyles();
  const { classes: basicInputStyleClasses } = useBasicInputStyles();
  const { bannerPhotoResolutions } = useGetAllBannerPhotoResolutions();
  const bannerPhotoInputRef = useRef<HTMLInputElement>(null);
  const savedBannerPhotoFilePath = useAppSelector(
    selectSavedBannerPhotoFilePath
  );
  const [previewBannerPhoto, setPreviewBannerPhoto] = useState<string | null>(
    null
  );
  const [bannerPhoto, setBannerPhoto] = useState<string>("");
  useEffect(() => {
    if (!savedBannerPhotoFilePath) {
      return;
    }

    setBannerPhoto(savedBannerPhotoFilePath);
  }, [savedBannerPhotoFilePath]);

  const validateFile = (file: File | undefined): boolean => {
    if (!file) {
      return false;
    }

    if (file.size > 2000000) {
      alert("Size of the banner photo is too big.");
      return false;
    }

    return true;
  };

  const resetBannerPhotoDialog = () => {
    if (bannerPhotoInputRef.current) {
      bannerPhotoInputRef.current.value = "";
    }
  };

  const onBannerPhotoChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files?.[0];

    const isValidFile: boolean = validateFile(file);

    if (!isValidFile) {
      setPreviewBannerPhoto(null);
      return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewBannerPhoto(reader.result as string);
    };
    reader.readAsDataURL(file!);

    setCompany({
      ...company,
      banner: {
        ...company.banner,
        companyId: company.general.companyId,
        id: company.banner.id,
        bannerPhotoFile: file,
      },
    });

    resetBannerPhotoDialog();
  };

  const onBannerPhotoRemove = () => {
    setCompany({
      ...company,
      banner: {
        ...company.banner,
        id: company.banner.id,
        bannerPhotoFile: undefined,
        resolutionId: EmptyGuid,
        url: "",
      },
    });
    dispatch(removeBannerPhoto());
    setBannerPhoto("");

    resetBannerPhotoDialog();

    setPreviewBannerPhoto(null);
  };

  const onChangeData = (
    index: number,
    updatedData: {
      title?: string;
      description?: string;
      fromDate?: string | null;
      toDate?: string | null;
    }
  ) => {
    const textAdvertisements =
      company.textAdvertisement.textAdvertisements || [];
    const updatedTextAdvertisements = [...textAdvertisements];
    const adToUpdate = updatedTextAdvertisements[index];
    updatedTextAdvertisements[index] = { ...adToUpdate, ...updatedData };

    setCompany({
      ...company,
      textAdvertisement: {
        ...company.textAdvertisement,
        textAdvertisements: [...updatedTextAdvertisements],
      },
    });
  };

  return (
    <>
      <Grid item my={2}>
        <Typography variant={"h5"} className={classes.bannerTitle}>
          Reklame (baneri / tekst)
        </Typography>
      </Grid>
      <Box className={classes.boxWrapper}>
        <Typography variant={"h6"} className={classes.subtitle}>
          Baneri
        </Typography>
        <Grid container>
          <Grid item md={6} xs={12} my={1}>
            <BasicInput
              label={"Dodaj URL"}
              boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.1)"}
              inputTitleColor={colors.inputTitle}
              multiline={false}
              placeholder="http://"
              value={company.banner.url}
              onChange={(e) =>
                setCompany({
                  ...company,
                  banner: {
                    ...company.banner,
                    url: e.target.value,
                  },
                })
              }
              error={
                validate &&
                !IsBannerValid(company.banner, bannerPhoto) &&
                !IsBannerUrlValid(company.banner)
              }
              errorMessage={"URL banera je obavezan."}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <input
              type="file"
              accept="image/*"
              onChange={onBannerPhotoChange}
              style={{ display: "none" }}
              id="banner-photo-upload"
              ref={bannerPhotoInputRef}
            />

            {!(previewBannerPhoto || savedBannerPhotoFilePath) && (
              <label htmlFor="banner-photo-upload">
                <AppButton
                  component="span"
                  label={"Dodaj baner"}
                  color={colors.primary}
                  hover={colors.primary}
                  startIcon={<AddPhotoAlternateOutlinedIcon />}
                />
              </label>
            )}
            {(previewBannerPhoto || savedBannerPhotoFilePath) && (
              <>
                <Box mt={2}>
                  <img
                    src={
                      previewBannerPhoto ??
                      PoslovniImenikEnv.Prod + "/" + savedBannerPhotoFilePath
                    }
                    alt="Dodata fotografija banera"
                    className={classes.logoImg}
                  />
                </Box>
                <Grid container display="flex">
                  <Grid item>
                    <AppButton
                      type="submit"
                      color={colors.green}
                      hover={colors.green}
                      label="Promeni"
                      onClick={() => bannerPhotoInputRef.current?.click()}
                      width="100%"
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {validate &&
              !IsBannerValid(company.banner, bannerPhoto) &&
              !IsBannerPhotoValid(company.banner, bannerPhoto) && (
                <span className={basicInputStyleClasses.errorMessage}>
                  Fotografija banera je obavezna.
                </span>
              )}
          </Grid>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={company.banner.resolutionId}
              onChange={(e) =>
                setCompany({
                  ...company,
                  banner: {
                    ...company.banner,
                    resolutionId: e.target.value,
                  },
                })
              }
            >
              <Grid container className={classes.checkboxWrapper}>
                {bannerPhotoResolutions?.map((x) => (
                  <Grid item>
                    <FormControlLabel
                      value={x.id}
                      control={<Radio />}
                      label={`Baner ${x.width + "x" + x.height}`}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
            {validate &&
              !IsBannerValid(company.banner, bannerPhoto) &&
              !IsBannerResolutionValid(company.banner) && (
                <span className={basicInputStyleClasses.errorMessage}>
                  Rezolucija banera je obavezna.
                </span>
              )}
          </FormControl>

          <Grid item md={12} container justifyContent={"right"}>
            <RemoveIconButton onClick={onBannerPhotoRemove} />
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.boxWrapper} mt={2}>
        <Typography variant={"h6"} className={classes.subtitle}>
          Tekstualna reklama
        </Typography>
        {company.textAdvertisement.textAdvertisements.map((x, i) => (
          <>
            <Grid container mt={i !== 0 ? 4 : 0} key={i}>
              <Grid item md={6} sm={12} xs={12}>
                <BasicInput
                  label={"Naslov"}
                  boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.1)"}
                  onChange={(event) => {
                    const updatedData = { title: event.target.value };
                    onChangeData(i, updatedData);
                  }}
                  inputTitleColor={colors.inputTitle}
                  multiline={false}
                  value={x.title}
                  error={
                    validate && !IsTextAdValid(x) && !IsTextAdTitleValid(x)
                  }
                  errorMessage={"Naslov je obavezan."}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12} mt={1}>
                <BasicInput
                  label={"Tekst za reklamu"}
                  boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.1)"}
                  inputTitleColor={colors.inputTitle}
                  multiline={true}
                  value={x.description}
                  onChange={(event) => {
                    const updatedData = { description: event.target.value };
                    onChangeData(i, updatedData);
                  }}
                  rows={5}
                  count={"1000"}
                  inputProps={{
                    maxLength: 1000,
                  }}
                  endAdornment={
                    <Typography
                      sx={{ position: "absolute", bottom: 5, right: 5 }}
                      align="right"
                      variant="caption"
                    ></Typography>
                  }
                  error={
                    validate &&
                    !IsTextAdValid(x) &&
                    !IsTextAdDescriptionValid(x)
                  }
                  errorMessage={"Tekst za reklamu je obavezan."}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12} mt={1}>
                <Typography variant={"h6"} className={classes.subtitle}>
                  Istaknuta reklama
                </Typography>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={x.isHighlighted}
                        onChange={(e, checked) => {
                          const textAds = [
                            ...company.textAdvertisement.textAdvertisements,
                          ];

                          const updatedTextAds = textAds.map(
                            (textAd, index) => {
                              if (index === i) {
                                return {
                                  ...textAd,
                                  isHighlighted: checked,
                                };
                              }
                              return textAd;
                            }
                          );

                          setCompany({
                            ...company,
                            textAdvertisement: {
                              ...company.textAdvertisement,
                              textAdvertisements: updatedTextAds,
                            },
                          });
                        }}
                      />
                    }
                    label="Istakni reklamu"
                  />
                </FormGroup>
              </Grid>
              <Grid item md={6} sm={12} xs={12} mt={1} spacing={1}>
                <Typography variant={"h6"} className={classes.subtitle}>
                  Vrijeme trajanja reklame
                </Typography>
                <Grid display="flex">
                  <Grid sm={12} xs={12}>
                    <BasicInput
                      inputTitleColor={colors.inputTitle}
                      value={x.fromDate ?? ""}
                      onChange={(event) => {
                        const updatedData = {
                          fromDate:
                            event.target.value?.trim().length > 0
                              ? event.target.value.trim()
                              : null,
                        };
                        onChangeData(i, updatedData);
                      }}
                      type="date"
                      boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.1)"}
                      multiline={false}
                      error={
                        validate &&
                        !IsTextAdValid(x) &&
                        !IsTextAdFromDateValid(x)
                      }
                      errorMessage={"Datum je obavezan."}
                    />
                  </Grid>
                  <Grid sm={12} xs={12} ml={1}>
                    <BasicInput
                      inputTitleColor={colors.inputTitle}
                      value={x.toDate ?? ""}
                      onChange={(event) => {
                        const updatedData = {
                          toDate:
                            event.target.value?.trim().length > 0
                              ? event.target.value.trim()
                              : null,
                        };
                        onChangeData(i, updatedData);
                      }}
                      type="date"
                      boxShadow={"0px 0px 7px rgba(0, 0, 0, 0.1)"}
                      multiline={false}
                      error={
                        validate && !IsTextAdValid(x) && !IsTextAdToDateValid(x)
                      }
                      errorMessage={"Datum je obavezan."}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12} container justifyContent={"right"}>
              {
                <RemoveIconButton
                  onClick={() => {
                    let updatedAds =
                      company.textAdvertisement.textAdvertisements.filter(
                        (_, index) => index !== i
                      );

                    if (updatedAds.length === 0) {
                      updatedAds = [
                        ...INIT_TEXT_ADVERTISEMENT.textAdvertisements,
                      ];
                    }

                    setCompany({
                      ...company,
                      textAdvertisement: {
                        ...company.textAdvertisement,
                        textAdvertisements: updatedAds,
                      },
                    });
                  }}
                />
              }
              {i === company.textAdvertisement.textAdvertisements.length - 1 &&
                AreAllTextAdvertisementsValid(
                  company.textAdvertisement.textAdvertisements
                ) && (
                  <AddIconButton
                    onClick={() => {
                      setCompany({
                        ...company,
                        textAdvertisement: {
                          ...company.textAdvertisement,
                          textAdvertisements: [
                            ...company.textAdvertisement.textAdvertisements,
                            ...[...INIT_TEXT_ADVERTISEMENT.textAdvertisements],
                          ],
                        },
                      });
                    }}
                  />
                )}
            </Grid>
          </>
        ))}
      </Box>
    </>
  );
}
