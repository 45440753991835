import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddTwoToneIcon from "@mui/icons-material/PersonAddTwoTone";
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import { colors } from "../../app/theme/colors";
import AppButton from "../../components/appButton/appButton";
import AddCircleIconButton from "../../components/icons/AddCircleIconButton";
import EditIconButton from "../../components/icons/EditIconButton";
import RemoveCircleIconButton from "../../components/icons/RemoveCircleIconButton";
import { ApiStatus } from "../../models/app/apiStatus";
import { User } from "../../models/users/user";
import {
  getAuthenticatedUser,
  selectAuthenticatedUser,
} from "../../slice/authSlice";
import {
  getAllUsers,
  selectSetUserActiveStatusRequestStatus,
  selectUsers,
  setUserActiveStatus,
} from "../../slice/userSlice";
import EditUserModal from "../modals/userManagement/EditUserModal";
import { useUserManagementStyles } from "./useUserManagementStyles";
import { INIT_USER } from "./userManagementInitData";

interface TableRowProps {
  isEven: boolean;
}

const ClientTableRow = styled(TableRow)<TableRowProps>(({ theme, isEven }) => ({
  backgroundColor: isEven ? "#E8EDF6" : undefined,
  borderRadius: isEven ? "8px" : undefined,
  "&:hover": {
    backgroundColor: "#4d8cf594",
    cursor: "pointer",
    "& .MuiTableCell-root": {
      color: "#fff",
    },
  },
}));

type Props = {};

export default function UserManagement({}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useAppDispatch();
  const setUserStatusRequestStatus = useAppSelector(
    selectSetUserActiveStatusRequestStatus
  );
  const authenticatedUser = useAppSelector(selectAuthenticatedUser);
  const { classes } = useUserManagementStyles();
  const [users, setUsers] = useState<User[]>([]);
  const [user, setUser] = useState<User>({ ...INIT_USER });
  const [isOpenEditModal, setIsOpenEditModal] = useState<boolean>(false);
  const fetchedUsers = useAppSelector(selectUsers);

  const onSetUserStatus = async (userId: string, isActive: boolean) => {
    await dispatch(setUserActiveStatus({ id: userId, isActive: isActive }));
    await dispatch(getAllUsers());
  };

  const onOpenEditModal = (x: User) => {
    setUser({ ...x });
    setIsOpenEditModal(true);
  };

  const onCloseEditModal = () => {
    setIsOpenEditModal(false);
  };

  const fetchData = async () => {
    await dispatch(getAuthenticatedUser());
    await dispatch(getAllUsers());
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setUsers([...fetchedUsers]);
  }, [fetchedUsers]);

  return (
    <Box
      style={{
        backgroundColor: theme.palette.background.default,
        padding: isMobile ? "10px" : "30px",
      }}
    >
      <Box className={classes.titleWrapper}>
        <FormatListBulletedIcon className={classes.icon} />
        <Typography variant="h5" align="left" className={classes.title}>
          Korisnički nalozi
        </Typography>
      </Box>

      <Box mb={2}>
        <Divider className={classes.divider} />
      </Box>

      <Box mb={2} sx={{ textAlign: "end" }}>
        <AppButton
          onClick={() => onOpenEditModal(INIT_USER)}
          label="Kreirajte novi nalog"
          color={colors.green}
          hover={colors.green}
          startIcon={<PersonAddTwoToneIcon />}
        />
      </Box>

      <Box>
        <Table>
          <TableBody>
            {users.map((x, i) => (
              <ClientTableRow key={x.id} isEven={i % 2 === 0}>
                <TableCell>
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <PersonIcon htmlColor="#747474" />
                  </Box>
                </TableCell>
                <TableCell>
                  {x.firstName} {x.lastName}
                </TableCell>
                {!isMobile && (
                  <>
                    <TableCell>{x.email}</TableCell>
                    <TableCell>{x.phone}</TableCell>
                    <TableCell>
                      {x.isActive ? "Aktivan" : "Deaktiviran"}
                    </TableCell>
                  </>
                )}
                <TableCell>
                  <EditIconButton
                    tooltipTitle="Izmenite podatke naloga"
                    onClick={() => onOpenEditModal(x)}
                  />
                  {authenticatedUser?.id !== x.id && (
                    <>
                      {x.isActive ? (
                        <RemoveCircleIconButton
                          tooltipTitle="Deaktivirajte nalog"
                          onClick={() => onSetUserStatus(x.id, false)}
                          disabled={
                            setUserStatusRequestStatus === ApiStatus.Pending
                          }
                        />
                      ) : (
                        <AddCircleIconButton
                          tooltipTitle="Aktivirajte nalog"
                          onClick={() => onSetUserStatus(x.id, true)}
                          disabled={
                            setUserStatusRequestStatus === ApiStatus.Pending
                          }
                        />
                      )}
                    </>
                  )}
                </TableCell>
              </ClientTableRow>
            ))}
          </TableBody>
        </Table>
      </Box>

      <EditUserModal
        isOpen={isOpenEditModal}
        onClose={onCloseEditModal}
        user={user}
        setUser={setUser}
      />
    </Box>
  );
}
