import ChangeEmailRequest from "../../models/clientAccount/changeEmailRequest";
import { BannerForSaveCompany } from "../../models/companies/saveCompany/advertisement/bannerForSaveCompany";
import { TextAdvertisementForSaveCompany } from "../../models/companies/saveCompany/advertisement/textAdvertisementForSaveCompany";
import { CompanyStepper } from "../../models/companies/saveCompany/companyStepper";
import { ContactSection } from "../../models/companies/saveCompany/contact/contactSection";
import { LocationForSaveCompany } from "../../models/companies/saveCompany/contact/locationForSaveCompany";
import { SocialMediaForSaveCompany } from "../../models/companies/saveCompany/contact/socialMediaForSaveCompany";
import { AltTextForSaveCompany } from "../../models/companies/saveCompany/description/altTextForSaveCompany";
import { DescriptionForSaveCompany } from "../../models/companies/saveCompany/description/descriptionForSaveCompany";
import { DescriptionSection } from "../../models/companies/saveCompany/description/descriptionSection";
import { EmailForSaveCompany } from "../../models/companies/saveCompany/general/emailForSaveCompany";
import { GeneralSection } from "../../models/companies/saveCompany/general/generalSection";
import { PhoneForSaveCompany } from "../../models/companies/saveCompany/general/phoneForSaveCompany";
import { WebsiteForSaveCompany } from "../../models/companies/saveCompany/general/websiteForSaveCompany";
import { WorkingTimeForSaveCompany } from "../../models/companies/saveCompany/general/workingTimeForSaveCompany";
import { GalleryPhotoDto } from "../../models/companies/saveCompany/photos/galleryPhotoDto";
import { GalleryPhotoForSaveCompany } from "../../models/companies/saveCompany/photos/galleryPhotoForSaveCompany";
import { LogoDto } from "../../models/companies/saveCompany/photos/logoDto";
import { LogoForSaveCompany } from "../../models/companies/saveCompany/photos/logoForSaveCompany";
import WorkingDays from "../../models/days/workingDays";

export const EmptyGuid = "00000000-0000-0000-0000-000000000000";

export const INIT_PHONE: PhoneForSaveCompany = {
  id: EmptyGuid,
  phoneNumber: "",
  typeName: "",
};

export const INIT_WEBSITE: WebsiteForSaveCompany = {
  id: EmptyGuid,
  url: "",
};

export const INIT_EMAIL: EmailForSaveCompany = {
  id: EmptyGuid,
  emailAddress: "",
  typeName: "",
};

export const INIT_WORKING_DAY: WorkingDays = {
  id: EmptyGuid,
  name: "",
  orderNumber: 0,
  selected: false,
};

export const INIT_WORKING_TIME: WorkingTimeForSaveCompany = {
  id: EmptyGuid,
  fromTime: null,
  toTime: null,
  isClosed: null,
  isOpen24Hours: null,
  dayId: EmptyGuid,
}

export const INIT_GENERAL_SECTION: GeneralSection = {
  companyId: EmptyGuid,
  name: "",
  streetId: EmptyGuid,
  homeNumber: "",
  postCode: "",
  isPremium: false,
  isPublic: false,
  numberOfStars: null,
  categoryIds: [],
  phones: [{ ...INIT_PHONE }],
  websites: [{ ...INIT_WEBSITE }],
  emails: [{ ...INIT_EMAIL }],
  workingTimes: [
    {
      ...INIT_WORKING_TIME
    },
  ],
};

export const INIT_DESCRIPTION: DescriptionForSaveCompany = {
  id: EmptyGuid,
  longDescription: "",
  shortDescription: "",
};

export const INIT_ALT_TEXT: AltTextForSaveCompany = {
  id: EmptyGuid,
  text: "",
};

export const INIT_DESCRIPTION_SECTION: DescriptionSection = {
  companyId: EmptyGuid,
  description: {
    ...INIT_DESCRIPTION,
  },
  altText: {
    ...INIT_ALT_TEXT,
  },
};

export const INIT_LOGO_DTO: LogoDto = {
  id: EmptyGuid,
  logoPath: "",
};

export const INIT_LOGO: LogoForSaveCompany = {
  companyId: EmptyGuid,
};

export const INIT_GALLERY_PHOTO_DTO: GalleryPhotoDto = {
  id: EmptyGuid,
  orderNumber: 1,
};

export const INIT_GALLERY_PHOTO: GalleryPhotoForSaveCompany = {
  companyId: EmptyGuid,
  galleryPhotos: [],
};

export const INIT_BANNER: BannerForSaveCompany = {
  companyId: EmptyGuid,
  id: EmptyGuid,
  url: "",
  resolutionId: EmptyGuid,
};

export const INIT_TEXT_ADVERTISEMENT: TextAdvertisementForSaveCompany = {
  companyId: EmptyGuid,
  textAdvertisements: [{
    id: EmptyGuid,
    title: "",
    description: "",
    isHighlighted: false,
    fromDate: null,
    toDate: null,
  }]
};

export const INIT_SOCIAL_MEDIA_GROUPED_VALUE: SocialMediaForSaveCompany = {
  id: EmptyGuid,
  url: "",
};

export const INIT_LOCATION: LocationForSaveCompany = {
  id: EmptyGuid,
  urlCoordinates: "",
};

export const INIT_CONTACT_SECTION: ContactSection = {
  companyId: EmptyGuid,
  socialMedias: [],
  locations: [{ ...INIT_LOCATION }],
};

export const INIT_COMPANY_STEPPER: CompanyStepper = {
  general: { ...INIT_GENERAL_SECTION },
  description: { ...INIT_DESCRIPTION_SECTION },
  logo: { ...INIT_LOGO },
  galleryPhoto: { ...INIT_GALLERY_PHOTO },
  banner: { ...INIT_BANNER },
  textAdvertisement: { ...INIT_TEXT_ADVERTISEMENT },
  contact: { ...INIT_CONTACT_SECTION },
}

export   const INIT_EMAIL_CHANGE_REQUEST: ChangeEmailRequest = {
  accountId:  "",
  email: "",
};