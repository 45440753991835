import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import PublicIcon from "@mui/icons-material/Public";
import {
  Autocomplete,
  Avatar,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PoslovniImenikEnv } from "../../../app/redux/api";
import { useAppDispatch, useAppSelector } from "../../../app/redux/hooks";
import { colors } from "../../../app/theme/colors";
import AppButton from "../../../components/appButton/appButton";
import {
  getCompanyDetail,
  getCompanyDetailForEditById,
  selectCompanyDetail,
  selectCompanyDetailStatus,
  selectSavedGeneralData,
  selectSavedLogo,
} from "../../../slice/companySlice";
import Gallery from "./Gallery";
import TranslateDay from "./TranslateDay";
import { useFullDetailsModalStyles } from "./useFullDetailsModalStyles";
import BasicInput from "../../../components/basicInput/basicInput";
import { CountryModel } from "../../../models/address/countryModel";
import {
  getAllCountries,
  getPlacesByCountryId,
  getStreetsByPlaceId,
  selectAllCountries,
  selectPlacesByCountryId,
  selectStreetsByPlaceId,
} from "../../../slice/addressSlice";
import { PlaceModel } from "../../../models/address/placeModel";
import { StreetModel } from "../../../models/address/streetModel";
import { EmptyGuid, INIT_COMPANY_STEPPER } from "../../stepper/initialData";
import { CityModel } from "../../../models/address/cityModel";
import { CompanyStepper } from "../../../models/companies/saveCompany/companyStepper";
import HorizontalStepper from "../../stepper/stepper";

interface Props {
  open: boolean;
  handleClose: () => void;
  companyId: string | null;
}

export default function EditCompanyModal({
  handleClose,
  open,
  companyId,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { classes } = useFullDetailsModalStyles();
  const theme = useTheme();

  const countries = useAppSelector(selectAllCountries);
  const placesByCountryId = useAppSelector(selectPlacesByCountryId);
  const streetsByPlaceId = useAppSelector(selectStreetsByPlaceId);
  const companyById = useAppSelector(selectCompanyDetail);
  const companyGeneralData = useAppSelector(selectSavedGeneralData);
  const companyLogo = useAppSelector(selectSavedLogo);

  const fullDetailsStatus = useAppSelector(selectCompanyDetailStatus);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const [place, setPlace] = useState<PlaceModel | null>(null);
  const [street, setStreet] = useState<StreetModel | null>(null);
  const [company, setCompany] = useState<CompanyStepper>({
    ...INIT_COMPANY_STEPPER,
  });
  const [country, setCountry] = useState<CountryModel | null>(null);

  useEffect(() => {
    if (!companyId) {
      return;
    }
    dispatch(getCompanyDetailForEditById(companyId));
    dispatch(getAllCountries());
  }, [companyId]);

  useEffect(() => {
    let selectedPlace;
    let selectedStreet;
    if (companyGeneralData?.countryId) {
      dispatch(getPlacesByCountryId(companyGeneralData?.countryId));
      const countryById = countries?.find(
        (x) => x.id === companyGeneralData.countryId
      );
      setCountry(countryById ?? null);

      const placesBySelectedCityId = placesByCountryId;
      selectedPlace = placesBySelectedCityId?.find(
        (x) => x.id === companyGeneralData.placeId
      );

      setPlace(selectedPlace ?? null);
    }
  }, [companyGeneralData]);

  useEffect(() => {
    if (place) {
      dispatch(getStreetsByPlaceId(place.id));
    }
  }, [place]);

  useEffect(() => {
    let selectedStreet;

    if (streetsByPlaceId) {
      const streetsBySelectedPlaceId = streetsByPlaceId;
      selectedStreet = streetsBySelectedPlaceId?.find(
        (x) => x.id === companyGeneralData?.streetId
      );

      setStreet(selectedStreet ?? null);
    }
  }, [streetsByPlaceId]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      id="fullDetailsModal_dialog"
      sx={{ minHeight: "500px" }}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <HorizontalStepper component="EditModal" companyEditId={companyId} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <AppButton
          onClick={handleClose}
          color={colors.primary}
          hover={colors.primary}
          label={t("common.close")}
        />
      </DialogActions>
    </Dialog>
  );
}

export function formatTimeTo24Hours(time: string) {
  const timeParts = time.split(" ");
  const hoursMinutes = timeParts[0].split(":");
  let hours = parseInt(hoursMinutes[0]);
  const minutes = parseInt(hoursMinutes[1]);

  if (timeParts[1].toLowerCase() === "pm" && hours < 12) {
    hours += 12;
  } else if (timeParts[1].toLowerCase() === "am" && hours === 12) {
    hours = 0;
  }

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
}
