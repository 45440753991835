import { Grid, IconButton, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import { colors } from "../../app/theme/colors";
import { GetFormattedDate } from "../../common/helpers/helpers";
import {
  IsAllCompanyPhoneNumbersValid,
  IsAllCompanyPhoneTypesValid,
  IsCompanyCategoryValid,
  IsCompanyCountryValid,
  IsCompanyNameValid,
  IsCompanyPlaceValid,
  IsCompanyStreetValid,
} from "../../common/validations/generalSectionValidations";
import AppButton from "../../components/appButton/appButton";
import RemoveIconButton from "../../components/icons/RemoveIconButton";
import useGetAllCategoryShortDetails from "../../hooks/categories/useGetAllCategoryShortDetails";
import useGetAllSocialMediaTypes from "../../hooks/contacts/useGetAllSocialMediaTypes";
import { CountryModel } from "../../models/address/countryModel";
import { PlaceModel } from "../../models/address/placeModel";
import { StreetModel } from "../../models/address/streetModel";
import { CategoryShortDetail } from "../../models/categories/categoryShortDetail";
import { CompanyDetail } from "../../models/companies/companyDetail";
import { CompanyShortDetail } from "../../models/companies/companyShortDetail";
import { BannerForSaveCompanyResponse } from "../../models/companies/saveCompany/advertisement/bannerForSaveCompanyResponse";
import { TextAdvertisementForSaveCompany } from "../../models/companies/saveCompany/advertisement/textAdvertisementForSaveCompany";
import { CompanyStepper } from "../../models/companies/saveCompany/companyStepper";
import { ContactSection } from "../../models/companies/saveCompany/contact/contactSection";
import { LocationForSaveCompany } from "../../models/companies/saveCompany/contact/locationForSaveCompany";
import { SocialMediaGrouped } from "../../models/companies/saveCompany/contact/socialMediaGrouped";
import { DescriptionSection } from "../../models/companies/saveCompany/description/descriptionSection";
import { GeneralSection } from "../../models/companies/saveCompany/general/generalSection";
import {
  getPlacesByCountryId,
  getStreetsByPlaceId,
  selectAllCountries,
} from "../../slice/addressSlice";
import {
  getAllCompanyShortDetails,
  getAllDays,
  getAllModules,
  getCompanyDetailForEditById,
  getOwnerAccountByCompanyId,
  removeBannerPhoto,
  removeCompany,
  removeGalleryPhotos,
  removeLogo,
  resetCheckWebsiteNameStatus,
  saveBanner,
  saveContact,
  saveDescriptionData,
  saveGeneralData,
  saveTextAdvertisements,
  selectChangeEmailStatus,
  selectCompanyShortDetails,
  selectModules,
  selectOwnerAccount,
  selectSavedBannerPhotoFilePath,
  selectSavedGeneralData,
} from "../../slice/companySlice";
import BannerSection from "../clientManagement/bannerSection/bannerSection";
import DescriptionDataSection from "../clientManagement/descriptionSection/descriptionSection";
import GeneralDataSection from "../clientManagement/generalDataSection/generalDataSection";
import ImagesSection from "../clientManagement/imagesSection/imagesSection";
import RemoveCompanyModal from "../modals/removeCompany/RemoveCompanyModal";
import { SelectCompany } from "../clientManagement/selectCompany/SelectCompany";
import SocialeMediaSection from "../clientManagement/socialMediaSection/socialMediaSection";
import {
  EmptyGuid,
  INIT_ALT_TEXT,
  INIT_COMPANY_STEPPER,
  INIT_EMAIL,
  INIT_EMAIL_CHANGE_REQUEST,
  INIT_LOCATION,
  INIT_PHONE,
  INIT_TEXT_ADVERTISEMENT,
  INIT_WEBSITE,
  INIT_WORKING_TIME,
} from "./initialData";
import {
  AreAllTextAdvertisementsValid,
  IsBannerValid,
} from "../../common/validations/advertisementSectionValidations";
import ClientAccountRequest from "../../models/clientAccount/clientAccountRequest";
import CheckWebsiteNameModel from "../../models/clientAccount/checkWebsiteNameModel";
import ChangeEmailRequest from "../../models/clientAccount/changeEmailRequest";

interface Step {
  label: string;
  content: JSX.Element;
}

interface Props {
  component: string;
  companyEditId?: string | null;
}

export default function HorizontalStepper({ component, companyEditId }: Props) {
  const dispatch = useAppDispatch();

  const ownerAccount = useAppSelector(selectOwnerAccount);
  const changeEmailStatus = useAppSelector(selectChangeEmailStatus);
  const selectGeneralData = useAppSelector(selectSavedGeneralData);
  const companyShortDetails = useAppSelector(selectCompanyShortDetails);
  const modules = useAppSelector(selectModules);
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState<{
    [k: number]: boolean;
  }>({});
  const [company, setCompany] = useState<CompanyStepper>({
    ...INIT_COMPANY_STEPPER,
  });
  const [validate, setValidate] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] =
    useState<CompanyShortDetail | null>(null);
  const { categoryShortDetails } = useGetAllCategoryShortDetails();
  const [categories, setCategories] = useState<CategoryShortDetail[]>(
    categoryShortDetails?.filter((x, i) => {
      const find = company.general.categoryIds.find((y) => x.id === y);
      if (!find) {
        return;
      }

      return find;
    }) ?? []
  );
  const [isOpenRemoveModal, setIsOpenRemoveModal] = useState<boolean>(false);
  const selectedBannerPhoto = useAppSelector(selectSavedBannerPhotoFilePath);
  const [bannerPhoto, setBannerPhoto] = useState<string>(
    selectedBannerPhoto ?? ""
  );

  const countries = useAppSelector(selectAllCountries);

  const [country, setCountry] = useState<CountryModel | null>(null);
  const [place, setPlace] = useState<PlaceModel | null>(null);
  const [street, setStreet] = useState<StreetModel | null>(null);

  const { socialMediaTypes } = useGetAllSocialMediaTypes();
  const INIT_CLIENT_ACCOUNT: ClientAccountRequest = {
    clientAccount: {
      associationId: EmptyGuid,
      companyId: company.general.companyId,
      email: "",
      firstName: "",
      id: EmptyGuid,
      isActive: false,
      isDeleted: false,
      lastName: "",
      phone: "",
      roleName: "",
      rolePriority: 0,
    },
    moduleIds: [],
    websiteName: "",
  };

  const [isCheckedMySite, setIsCheckedMySite] = useState<boolean>(false);
  const [isCheckedChangeEmail, setIsCheckedChangeEmail] =
    useState<boolean>(false);

  const INIT_WEBSITE_CHECK: CheckWebsiteNameModel = {
    websiteName: "",
    companyId: company.general.companyId,
  };

  const [checkWebsite, setCheckWebsite] =
    useState<CheckWebsiteNameModel>(INIT_WEBSITE_CHECK);

  const [clientAccount, setClientAccount] =
    useState<ClientAccountRequest>(INIT_CLIENT_ACCOUNT);
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const initialErrors = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  };

  const [emailChange, setEmailChange] = useState<ChangeEmailRequest>(
    INIT_EMAIL_CHANGE_REQUEST
  );
  const setSocialMedias = (socialMedias: SocialMediaGrouped[]) => {
    setCompany({
      ...company,
      contact: {
        ...company.contact,
        socialMedias: [...socialMedias],
      },
    });
  };

  useEffect(() => {
    if (ownerAccount) {
      setClientAccount(ownerAccount);
      setEmailChange((prevState: ChangeEmailRequest) => ({
        ...prevState,
        accountId: ownerAccount.clientAccount.id,
        email: ownerAccount.clientAccount.email,
      }));
    }
  }, [ownerAccount]);
  const steps: Step[] = [
    {
      label: "",
      content: (
        <GeneralDataSection
          setEmailChange={setEmailChange}
          emailChange={emailChange}
          isCheckedChangeEmail={isCheckedChangeEmail}
          setIsCheckedChangeEmail={setIsCheckedChangeEmail}
          setCheckWebsite={setCheckWebsite}
          checkWebsite={checkWebsite}
          isCheckedMySite={isCheckedMySite}
          setIsCheckedMySite={setIsCheckedMySite}
          ownerAccount={ownerAccount}
          clientAccount={clientAccount}
          setClientAccount={setClientAccount}
          company={company}
          setCompany={setCompany}
          categories={categories}
          setCategories={setCategories}
          categoryShortDetails={categoryShortDetails ?? []}
          country={country}
          setCountry={setCountry}
          place={place}
          setPlace={setPlace}
          street={street}
          setStreet={setStreet}
          validate={validate}
          errors={errors}
          setErrors={setErrors}
        />
      ),
    },
    {
      label: "",
      content: (
        <DescriptionDataSection company={company} setCompany={setCompany} />
      ),
    },
    {
      label: "",
      content: <ImagesSection company={company} setCompany={setCompany} />,
    },
    {
      label: "",
      content: (
        <BannerSection
          company={company}
          setCompany={setCompany}
          validate={validate}
        />
      ),
    },
    {
      label: "",
      content: (
        <SocialeMediaSection
          company={company}
          setCompany={setCompany}
          socialMedias={company.contact.socialMedias}
          setSocialMedias={setSocialMedias}
        />
      ),
    },
  ];

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const saveByStep = async () => {
    let savedCompanyId = "";

    if (activeStep === 0) {
      const response = await dispatch(saveGeneralData(company.general));

      if (!response.payload) {
        return;
      }

      let saved = { ...(response.payload as GeneralSection) };

      if (saved.phones.length === 0) {
        saved.phones = [{ id: EmptyGuid, phoneNumber: "", typeName: "" }];
      }
      if (saved.emails.length === 0) {
        saved.emails = [{ id: EmptyGuid, emailAddress: "", typeName: "" }];
      }
      if (saved.websites.length === 0) {
        saved.websites = [{ id: EmptyGuid, url: "" }];
      }
      if (saved.workingTimes.length === 0) {
        saved.workingTimes = [
          {
            id: EmptyGuid,
            fromTime: null,
            toTime: null,
            isClosed: null,
            isOpen24Hours: null,
            dayId: EmptyGuid,
          },
        ];
      }

      setCompany({
        ...company,
        general: { ...saved },
        description: { ...company.description, companyId: saved.companyId },
        logo: { ...company.logo, companyId: saved.companyId },
        galleryPhoto: { ...company.galleryPhoto, companyId: saved.companyId },
        banner: { ...company.banner, companyId: saved.companyId },
        textAdvertisement: {
          ...company.textAdvertisement,
          companyId: saved.companyId,
        },
        contact: { ...company.contact, companyId: saved.companyId },
      });

      savedCompanyId = saved.companyId;
    } else if (activeStep === 1) {
      const response = await dispatch(saveDescriptionData(company.description));

      if (!response.payload) {
        return;
      }

      let saved = { ...(response.payload as DescriptionSection) };

      if (!saved.altText) {
        saved = { ...saved, altText: { ...INIT_ALT_TEXT } };
      }

      setCompany({
        ...company,
        description: { ...saved, companyId: company.general.companyId },
      });

      savedCompanyId = saved.companyId;
    } else if (activeStep === 3) {
      // Map text advertisements
      const saveTextAdvertisementResponse = await dispatch(
        saveTextAdvertisements(company.textAdvertisement)
      );

      if (!saveTextAdvertisementResponse.payload) {
        return;
      }

      let savedTextAdvertisement = {
        ...(saveTextAdvertisementResponse.payload as TextAdvertisementForSaveCompany),
      };

      if (savedTextAdvertisement.textAdvertisements.length === 0) {
        savedTextAdvertisement = {
          ...savedTextAdvertisement,
          textAdvertisements: [...INIT_TEXT_ADVERTISEMENT.textAdvertisements],
        };
      }

      const textAdvertisements = [
        ...savedTextAdvertisement.textAdvertisements,
      ].map((x, i) => {
        return {
          ...x,
          fromDate: x.fromDate ? GetFormattedDate(x.fromDate) : null,
          toDate: x.toDate ? GetFormattedDate(x.toDate) : null,
        };
      });
      savedTextAdvertisement = {
        ...savedTextAdvertisement,
        textAdvertisements: [...textAdvertisements],
      };

      // Map banner
      const saveBannerResponse = await dispatch(saveBanner(company.banner));

      if (!saveBannerResponse.payload) {
        return;
      }

      let savedBanner = {
        ...(saveBannerResponse.payload as BannerForSaveCompanyResponse),
      };

      setCompany({
        ...company,
        banner: {
          ...savedBanner,
          companyId: company.general.companyId,
        },
        textAdvertisement: {
          ...savedTextAdvertisement,
          companyId: company.general.companyId,
        },
      });

      savedCompanyId = company.general.companyId;
    } else if (activeStep === 4) {
      const response = await dispatch(saveContact(company.contact));
      if (!response.payload) {
        return;
      }
      let saved = {
        ...(response.payload as ContactSection),
      };
      const updatedCompany = {
        ...company,
        contact: {
          ...saved,
          companyId: company.general.companyId,
        },
      };
      setCompany({ ...updatedCompany });

      savedCompanyId = saved.companyId;
    }

    if (savedCompanyId.trim().length === 0) {
      return;
    }

    const shortDetailsResponse = await dispatch(getAllCompanyShortDetails());
    if (shortDetailsResponse.payload) {
      const shortDetails = shortDetailsResponse.payload as CompanyShortDetail[];
      const shortDetail = shortDetails.find((x) => x.id === savedCompanyId);

      if (shortDetail) {
        await setSelectedCompanyAutocomplete(shortDetail);
      }
    }
  };

  const isValid = () => {
    setValidate(true);

    if (activeStep === 0) {
      if (
        !IsCompanyNameValid(company.general.name) ||
        !IsCompanyCategoryValid(categories) ||
        !IsCompanyCountryValid(country) ||
        !IsCompanyPlaceValid(place) ||
        !IsCompanyStreetValid(street) ||
        !IsAllCompanyPhoneTypesValid(company.general.phones) ||
        !IsAllCompanyPhoneNumbersValid(company.general.phones)
      ) {
        return false;
      }
    }

    if (activeStep === 3) {
      if (!IsBannerValid(company.banner, bannerPhoto)) {
        return false;
      }

      if (
        !AreAllTextAdvertisementsValid(
          company.textAdvertisement.textAdvertisements
        )
      ) {
        return false;
      }
    }

    setValidate(false);
    return true;
  };

  const handleNext = async () => {
    if (!isValid()) {
      return;
    }

    await saveByStep();

    // Next step
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = async () => {
    if (!isValid()) {
      return;
    }

    await saveByStep();

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => async () => {
    if (!isValid()) {
      return;
    }

    await saveByStep();
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  useEffect(() => {
    dispatch(getAllDays());
  }, []);

  const sortByOrderNumberProp = (
    list: SocialMediaGrouped[]
  ): SocialMediaGrouped[] => {
    return list.sort((a, b) => a.orderNumber - b.orderNumber);
  };

  const onChangeSelectedCompany = async (
    companyShortDetail: CompanyShortDetail | null
  ) => {
    if (!companyShortDetail) {
      setErrors(initialErrors);
      dispatch(resetCheckWebsiteNameStatus());
      setEmailChange(INIT_EMAIL_CHANGE_REQUEST);
      setIsCheckedChangeEmail(false);
      setIsCheckedMySite(false);
      setCategories([]);
      setClientAccount(INIT_CLIENT_ACCOUNT);
      setCheckWebsite(INIT_WEBSITE_CHECK);
      setIsCheckedMySite(false);
      const initCompany = { ...INIT_COMPANY_STEPPER };
      const initSocialMedias =
        socialMediaTypes?.map((x) => ({
          typeId: x.id,
          typeName: x.name,
          orderNumber: x.orderNumber,
          socialMedias: [{ id: EmptyGuid, url: "" }],
        })) ?? [];
      setCompany({
        ...initCompany,
        contact: {
          ...initCompany.contact,
          socialMedias: [...initSocialMedias],
          locations: [{ ...INIT_LOCATION }],
        },
      });
      setSelectedCompany(null);
      dispatch(removeBannerPhoto());
      dispatch(removeLogo());
      dispatch(removeGalleryPhotos());
      setCountry(null);
      setPlace(null);
      setStreet(null);
      setActiveStep(0);
      return;
    } else {
      setErrors(initialErrors);
      setEmailChange(INIT_EMAIL_CHANGE_REQUEST);
      setIsCheckedChangeEmail(false);
      setIsCheckedMySite(false);
      dispatch(resetCheckWebsiteNameStatus());
      setClientAccount(INIT_CLIENT_ACCOUNT);
      setCheckWebsite(INIT_WEBSITE_CHECK);
      setIsCheckedMySite(false);
    }
    dispatch(getOwnerAccountByCompanyId(companyShortDetail.id));

    await setSelectedCompanyAutocomplete(companyShortDetail);
    setValidate(true);
  };

  const setSelectedCompanyAutocomplete = async (
    companyShortDetail: CompanyShortDetail
  ) => {
    // Get detail
    const response = await dispatch(
      getCompanyDetailForEditById(companyShortDetail.id)
    );

    if (!response.payload) {
      return;
    }

    let companyDetail: CompanyDetail = {
      ...(response.payload as CompanyDetail),
    };

    setCategories(
      categoryShortDetails?.filter((x) =>
        companyDetail.general.categoryIds.includes(x.id)
      ) ?? []
    );

    // Country
    const countryById = countries?.find(
      (x) => x.id === companyDetail.general.countryId
    );
    setCountry(countryById ?? null);

    // Place
    let selectedPlace;
    if (countryById) {
      const response = await dispatch(getPlacesByCountryId(countryById.id));

      if (!response.payload) {
        return;
      }

      const placesBySelectedCityId = response.payload as PlaceModel[];
      selectedPlace = placesBySelectedCityId?.find(
        (x) => x.id === companyDetail.general.placeId
      );

      setPlace(selectedPlace ?? null);
    }

    // Street
    let selectedStreet;
    if (selectedPlace) {
      const response = await dispatch(getStreetsByPlaceId(selectedPlace.id));

      if (!response.payload) {
        return;
      }

      const streetsBySelectedPlaceId = response.payload as StreetModel[];
      selectedStreet = streetsBySelectedPlaceId?.find(
        (x) => x.id === companyDetail.general.streetId
      );

      setStreet(selectedStreet ?? null);
    }

    // Map general section
    if (companyDetail.general.phones.length === 0) {
      companyDetail = {
        ...companyDetail,
        general: {
          ...companyDetail.general,
          phones: [{ id: EmptyGuid, phoneNumber: "", typeName: "" }],
        },
      };
    }
    if (companyDetail.general.emails.length === 0) {
      companyDetail = {
        ...companyDetail,
        general: {
          ...companyDetail.general,
          emails: [{ id: EmptyGuid, emailAddress: "", typeName: "" }],
        },
      };
    }
    if (companyDetail.general.websites.length === 0) {
      companyDetail = {
        ...companyDetail,
        general: {
          ...companyDetail.general,
          websites: [{ id: EmptyGuid, url: "" }],
        },
      };
    }
    if (companyDetail.general.workingTimes.length === 0) {
      companyDetail = {
        ...companyDetail,
        general: {
          ...companyDetail.general,
          workingTimes: [
            {
              ...INIT_WORKING_TIME,
            },
          ],
        },
      };
    }

    // Map description section
    if (!companyDetail.description.altText) {
      companyDetail = {
        ...companyDetail,
        description: {
          ...companyDetail.description,
          altText: { ...INIT_ALT_TEXT },
        },
      };
    }

    // Map advertisement section
    if (companyDetail.textAdvertisement.textAdvertisements.length === 0) {
      companyDetail = {
        ...companyDetail,
        textAdvertisement: {
          ...companyDetail.textAdvertisement,
          textAdvertisements: [...INIT_TEXT_ADVERTISEMENT.textAdvertisements],
        },
      };
    }
    const textAdvertisements = [
      ...companyDetail.textAdvertisement.textAdvertisements,
    ].map((x, i) => {
      return {
        ...x,
        fromDate: x.fromDate ? GetFormattedDate(x.fromDate) : null,
        toDate: x.toDate ? GetFormattedDate(x.toDate) : null,
      };
    });
    companyDetail = {
      ...companyDetail,
      textAdvertisement: {
        ...companyDetail.textAdvertisement,
        textAdvertisements: [...textAdvertisements],
      },
    };

    // Map social medias
    let socialMedias: SocialMediaGrouped[] = [];
    const currentTypeIds = companyDetail.contact.socialMedias.map(
      (x) => x.typeId
    );
    if (socialMediaTypes) {
      const missingTypes = socialMediaTypes.filter(
        (x) => !currentTypeIds.includes(x.id)
      );
      const addedMissing: SocialMediaGrouped[] = missingTypes.map((x) => ({
        typeId: x.id,
        typeName: x.name,
        orderNumber: x.orderNumber,
        socialMedias: [{ id: EmptyGuid, url: "" }],
      }));
      const combined = companyDetail.contact.socialMedias.concat(addedMissing);
      socialMedias = sortByOrderNumberProp(combined);
    }

    // Map locations
    let locations: LocationForSaveCompany[] = [];
    if (companyDetail.contact.locations.length === 0) {
      locations = [{ ...INIT_LOCATION }];
    } else {
      locations = [...companyDetail.contact.locations];
    }

    // Map company detail to company stepper
    setCompany({
      ...INIT_COMPANY_STEPPER,
      general: {
        ...companyDetail.general,
        isPublic: companyDetail.general.isPublic ?? false, // Add default value for isPublic
      },
      description: {
        ...companyDetail.description,
      },
      banner: {
        ...companyDetail.banner,
      },
      textAdvertisement: {
        ...companyDetail.textAdvertisement,
      },
      contact: {
        ...companyDetail.contact,
        socialMedias: [...socialMedias],
        locations: [...locations],
      },
    });

    // Set selected company in autocomplete
    setSelectedCompany(companyShortDetail);
  };

  const handleOpenRemoveModal = () => {
    setIsOpenRemoveModal(true);
  };

  const handleCloseRemoveModal = () => {
    setIsOpenRemoveModal(false);
  };

  const handleRemoveCompany = async () => {
    if (!selectedCompany) {
      return;
    }

    const companyId = selectedCompany.id;

    const removeCompanyResponse = await dispatch(removeCompany(companyId));

    if (!removeCompanyResponse.payload) {
      return;
    }

    onChangeSelectedCompany(null);

    handleCloseRemoveModal();

    const shortDetailsResponse = await dispatch(getAllCompanyShortDetails());

    if (!shortDetailsResponse.payload) {
      return;
    }

    setActiveStep(0);
  };

  useEffect(() => {
    setBannerPhoto(selectedBannerPhoto ?? "");
  }, [selectedBannerPhoto]);

  useEffect(() => {
    dispatch(getAllModules());
    setClientAccount(INIT_CLIENT_ACCOUNT);
  }, []);

  useEffect(() => {
    const fetchCompanyDetails = async () => {
      if (component === "EditModal" && companyEditId) {
        dispatch(getOwnerAccountByCompanyId(companyEditId));
        const response = await dispatch(
          getCompanyDetailForEditById(companyEditId)
        );
        if (!response.payload) {
          return;
        }
        let companyDetail: CompanyDetail = {
          ...(response.payload as CompanyDetail),
        };

        setCategories(
          categoryShortDetails?.filter((x) =>
            companyDetail.general.categoryIds.includes(x.id)
          ) ?? []
        );
        // Country
        const countryById = countries?.find(
          (x) => x.id === companyDetail.general.countryId
        );
        setCountry(countryById ?? null);

        // Place
        let selectedPlace;
        if (countryById) {
          const response = await dispatch(getPlacesByCountryId(countryById.id));

          if (!response.payload) {
            return;
          }

          const placesBySelectedCityId = response.payload as PlaceModel[];
          selectedPlace = placesBySelectedCityId?.find(
            (x) => x.id === companyDetail.general.placeId
          );

          setPlace(selectedPlace ?? null);
        }

        // Street
        let selectedStreet;
        if (selectedPlace) {
          const response = await dispatch(
            getStreetsByPlaceId(selectedPlace.id)
          );

          if (!response.payload) {
            return;
          }

          const streetsBySelectedPlaceId = response.payload as StreetModel[];
          selectedStreet = streetsBySelectedPlaceId?.find(
            (x) => x.id === companyDetail.general.streetId
          );

          setStreet(selectedStreet ?? null);
        }
        // Map general section
        if (companyDetail && companyDetail.general.companyId) {
          setCompany((prevState) => ({
            ...prevState,
            general: {
              ...prevState.general,
              companyId: companyDetail.general.companyId,
            },
          }));
        }
        if (companyDetail && companyDetail.general.name) {
          setCompany((prevState) => ({
            ...prevState,
            general: {
              ...prevState.general,
              name: companyDetail.general.name,
            },
          }));
        }
        if (companyDetail && companyDetail.general.isPremium) {
          setCompany((prevState) => ({
            ...prevState,
            general: {
              ...prevState.general,
              isPremium: companyDetail.general.isPremium,
            },
          }));
        }
        if (companyDetail && companyDetail.general.isPublic) {
          setCompany((prevState) => ({
            ...prevState,
            general: {
              ...prevState.general,
              isPublic: companyDetail.general.isPublic,
            },
          }));
        }
        if (companyDetail && companyDetail.general.postCode) {
          setCompany((prevState) => ({
            ...prevState,
            general: {
              ...prevState.general,
              postCode: companyDetail.general.postCode,
            },
          }));
        }
        if (companyDetail && companyDetail.general.homeNumber) {
          setCompany((prevState) => ({
            ...prevState,
            general: {
              ...prevState.general,
              homeNumber: companyDetail.general.homeNumber,
            },
          }));
        }
        if (companyDetail && companyDetail.general.phones) {
          setCompany((prevState) => ({
            ...prevState,
            general: {
              ...prevState.general,
              phones:
                companyDetail.general.phones.length > 0
                  ? companyDetail.general.phones.map((phone) => ({
                      id: phone.id,
                      phoneNumber: phone.phoneNumber,
                      typeName: phone.typeName,
                    }))
                  : [{ ...INIT_PHONE }],
            },
          }));
        }
        if (companyDetail && companyDetail.general.websites) {
          setCompany((prevState) => ({
            ...prevState,
            general: {
              ...prevState.general,
              websites:
                companyDetail.general.websites.length > 0
                  ? companyDetail.general.websites.map((website) => ({
                      id: website.id,
                      url: website.url,
                    }))
                  : [{ ...INIT_WEBSITE }],
            },
          }));
        }
        if (companyDetail && companyDetail.general.emails) {
          setCompany((prevState) => ({
            ...prevState,
            general: {
              ...prevState.general,
              emails:
                companyDetail.general.emails.length > 0
                  ? companyDetail.general.emails.map((email) => ({
                      emailAddress: email.emailAddress,
                      id: email.id,
                      typeName: email.typeName,
                    }))
                  : [{ ...INIT_EMAIL }],
            },
          }));
        }
        if (companyDetail && companyDetail.general.categoryIds) {
          setCompany((prevState) => ({
            ...prevState,
            general: {
              ...prevState.general,
              categoryIds:
                companyDetail.general.categoryIds.length > 0
                  ? companyDetail.general.categoryIds
                  : [],
            },
          }));
        }
        // Map description
        if (companyDetail && companyDetail.description) {
          setCompany((prevState) => ({
            ...prevState,
            description: {
              ...prevState.description,
              description: companyDetail.description.description,
              altText: companyDetail.description.altText,
              companyId: companyDetail.description.companyId,
            },
          }));
        }
        if (companyDetail && companyDetail.general.workingTimes) {
          setCompany((prevState) => ({
            ...prevState,
            general: {
              ...prevState.general,
              workingTimes:
                companyDetail.general.workingTimes.length > 0
                  ? companyDetail.general.workingTimes.map((workingTime) => ({
                      dayId: workingTime.dayId,
                      fromTime: workingTime.fromTime,
                      id: workingTime.id,
                      isClosed: workingTime.isClosed,
                      isOpen24Hours: workingTime.isOpen24Hours,
                      toTime: workingTime.toTime,
                    }))
                  : [{ ...INIT_WORKING_TIME }],
            },
          }));
        }
        if (companyDetail && companyDetail.logo) {
          setCompany((prevState) => ({
            ...prevState,
            logo: {
              ...prevState.logo,
              id: companyDetail.logo?.id,
              companyId: companyDetail.logo?.companyId ?? "",
              imageFileName: companyDetail.logo?.imageFileName,
            },
          }));
        }
        if (ownerAccount && ownerAccount?.websiteName !== "") {
          setIsCheckedMySite(true);
        } else {
          setIsCheckedMySite(false);
        }
      }
    };

    fetchCompanyDetails();
  }, [component, companyEditId]);

  useEffect(() => {
    if (street !== null && component === "EditModal") {
      setCompany((prevState) => ({
        ...prevState,
        general: {
          ...prevState.general,
          streetId: street.id,
        },
      }));
    }
  }, [street, component]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={1}>
          {component !== "EditModal" && (
            <Grid item xs={selectedCompany ? 11 : 12}>
              <SelectCompany
                selectedCompany={selectedCompany}
                onChangeSelectedCompany={onChangeSelectedCompany}
              />
            </Grid>
          )}

          {selectedCompany && (
            <Grid item xs={1} display="flex" justifyContent="start">
              <Tooltip title="Obrišite kompaniju">
              <RemoveIconButton onClick={handleOpenRemoveModal} />

              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Box>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step completed={completed[index]} key={index}>
            <StepButton
              color="inherit"
              disabled={index === activeStep}
              onClick={handleStep(index)}
            >
              {label.label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {steps[activeStep].content}
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <AppButton
                label={"Nazad"}
                disabled={activeStep === 0}
                onClick={handleBack}
                color={colors.inputTitle}
                hover={colors.inputTitle}
                width={"8%"}
              />
              <Box sx={{ flex: "1 1 auto" }} />
              <AppButton
                label={activeStep === 4 ? "Sačuvaj" : "Dalje"}
                onClick={handleNext}
                color={colors.primary}
                hover={colors.primary}
                width={"8%"}
              />
            </Box>
          </React.Fragment>
        )}
      </div>

      {selectedCompany && (
        <RemoveCompanyModal
          open={isOpenRemoveModal}
          handleClose={handleCloseRemoveModal}
          handleRemoveCompany={handleRemoveCompany}
          companyName={selectedCompany.name}
        />
      )}
    </Box>
  );
}
