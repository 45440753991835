import SendIcon from "@mui/icons-material/Send";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { KeyboardEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/redux/hooks";
import { routes } from "../../app/router/routes";
import { colors } from "../../app/theme/colors";
import { IsLoginValid } from "../../common/validations/authValidations";
import AppButton from "../../components/appButton/appButton";
import BasicInput from "../../components/basicInput/basicInput";
import logo from "../../images/logoFull.png";
import { ApiStatus } from "../../models/app/apiStatus";
import { LoginRequest } from "../../models/auth/loginRequest";
import { login, selectLoginRequestStatus } from "../../slice/authSlice";
import { useLoginFormStyles } from "./loginFormStyles";
import ForgotPasswordModal from "../modals/forgotPassword/ForgotPasswordModal";
import { LoginResponse } from "../../models/auth/loginResponse";
import ResetPasswordModal from "../modals/resetPassword/ResetPasswordModal";

const INIT_LOGIN_REQUEST: LoginRequest = {
  email: "",
  password: ""
};

export default function LoginForm() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { classes } = useLoginFormStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [account, setAccount] = useState<LoginRequest>({ ...INIT_LOGIN_REQUEST });
  const loginRequestStatus = useAppSelector(selectLoginRequestStatus);
  const [isOpenRequestForgotPasswordModal, setIsOpenRequestForgotPasswordModal] = useState<boolean>(false);
  const [isOpenResetPasswordModal, setIsOpenResetPasswordModal] = useState<boolean>(false);

  const onLogin = async () => {
    const isValid = IsLoginValid(account)
      && loginRequestStatus !== ApiStatus.Pending;

    if (!isValid) {
      return;
    }

    const response = await dispatch(login(account));

    if (!response.payload) {
      return;
    }

    const loginResponse = response.payload as LoginResponse;

    if (loginResponse.isFirstLogin) {
      onOpenResetPasswordModal();
    } else {
      navigate(routes.dashboard);
    }
  };

  const onEnterPressed = async (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      await onLogin();
    }
  }

  const onOpenRequestForgotPasswordModal = () => {
    setIsOpenRequestForgotPasswordModal(true);
  };

  const onCloseRequestForgotPasswordModal = () => {
    setIsOpenRequestForgotPasswordModal(false);
  };

  const onOpenResetPasswordModal = () => {
    setIsOpenResetPasswordModal(true);
  };

  const onCloseResetPasswordModal = () => {
    setIsOpenResetPasswordModal(false);
  };
  

  return (
    <Grid container direction={isMobile ? 'column-reverse' : 'row'} style={{ maxWidth: "1000px" }}>
      <Grid item md={6} className={classes.inputWrapper} sx={{ background: "white", minHeight: isMobile ? "200px" : "0" }}>
        <Grid container>
          <Grid item md={12} xs={12}>
            <Typography
              variant="h4"
              className={classes.title}
              textAlign={"center"}
            >
              Administracija
            </Typography>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            className={classes.formWrapper}
            mt={2}
          >
            <BasicInput
              label="E-mail"
              height={"40px"}
              inputTitleColor={"#4D8CF5"}
              multiline={false}
              value={account.email}
              onChange={(e) => setAccount({ ...account, email: e.target.value })}
              onKeyDown={onEnterPressed}
              name={"login_email"}
            />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            className={classes.formWrapper}
            mt={2}
          >
            <BasicInput
              label="Lozinka"
              height={"40px"}
              inputTitleColor={"#4D8CF5"}
              multiline={false}
              type={"password"}
              value={account.password}
              onChange={(e) => setAccount({ ...account, password: e.target.value })}
              onKeyDown={onEnterPressed}
              name={"login_password"}
            />
          </Grid>
          <Grid
            item
            md={12}
            mt={2}
            xs={12}
            textAlign="center"
            className={classes.buttonWrapper}
          >
            <AppButton
              label="Prijavi se"
              color={colors.primary}
              hover={colors.primary}
              startIcon={<SendIcon />}
              width="100%"
              onClick={onLogin}
              disabled={!IsLoginValid(account) || loginRequestStatus === ApiStatus.Pending}
            />
          </Grid>
          <Grid
            item
            md={12}
            mt={1}
            xs={12}
            textAlign="center"
            className={classes.buttonWrapper}
          >
            <Typography
              sx={{
                color: "#747474",
                cursor: "pointer"
              }}
              onClick={onOpenRequestForgotPasswordModal}
            >
              Zaboravili ste lozinku?
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6} xs={12} className={classes.descriptionWrapper} style={{ minHeight: isMobile ? "200px" : "0" }}>
        <Grid
          container
          item
          md={12}
          textAlign={"center"}
          justifyContent={"center"}
          alignItems={"center"}
          className={classes.logoWrapper}
        >
          <img src={logo} className={classes.logo} />
        </Grid>
      </Grid>

      <ForgotPasswordModal
        isOpen={isOpenRequestForgotPasswordModal}
        onClose={onCloseRequestForgotPasswordModal}
      />

      <ResetPasswordModal
        isOpen={isOpenResetPasswordModal}
        onClose={onCloseResetPasswordModal}
        user={account}
      />
    </Grid>
  );
}
