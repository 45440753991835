import { Autocomplete, TextField } from "@mui/material";
import { useGetAllCompanyShortDetails } from "../../../hooks/companies/useGetAllCompanyShortDetails";
import { CompanyShortDetail } from "../../../models/companies/companyShortDetail";
import { useGeneralDataSectionStyles } from "../generalDataSection/generalDataSectionStyles";

type Props = {
  selectedCompany: CompanyShortDetail | null;
  onChangeSelectedCompany: (company: CompanyShortDetail | null) => void;
};

export function SelectCompany({
  selectedCompany,
  onChangeSelectedCompany,
}: Props) {
  const { classes } = useGeneralDataSectionStyles();
  const { companyShortDetails } = useGetAllCompanyShortDetails();

  return (
    <Autocomplete
      options={companyShortDetails ?? []}
      value={selectedCompany}
      onChange={(e, newValue) => onChangeSelectedCompany(newValue)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) =>
        `${option.name} - ${option.address}${option.isDraft ? " (draft)" : ""}`
      }
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Klijent"
          label=""
          variant="outlined"
          size="small"
          fullWidth
        />
      )}
      className={classes.autocomplete}
      sx={{
        boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
      }}
    />
  );
}
